import React, { useState } from "react";
import axios from "axios";
import { API_URI } from "../../config";
import { InputForm } from "../contactForm/input/index"

import BackImage from "./assets/callback.png";

import {
  ContactContainer,
  TextContainer,
  ImageContainer,
  Picture,
  Title,
  Line,
  Button,
  Form,
  TextContent,
  BottomSection,
  FormMessage,
  CheckboxWrapper,
  CheckboxText,
  CheckboxInput,
  ErrorMessage,
  FormRadio,
  FormRadioLabel,
  FormInputLabel,
  RadioContainer,
  RadioButtonsContainer,
} from "./styles";

const ContactFormVolunteer = () => {
  const [inputs, setInputs] = useState({ receiveEmail: false });
  const [succesForm, setSuccesForm] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [showError, setError] = useState(false);

  const handleChange = (event) => {
    setSuccesForm(false);
    setErrorForm(false);
    const name = event.target.name;
    const value = event.target.value;
    if (event.target.type === "radio") {
      setInputs((values) => ({ ...values, experience: value }));
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.receiveEmail) {
      setError(false);
      try {
        await axios.post(API_URI + "/api/hospice/contactMailVolunteer/", inputs);
        setSuccesForm(true);
        setInputs({ receiveEmail: true });
      } catch (error) {
        setErrorForm(true);
      }
    } else {
      setError(true);
      e.preventDefault();
    }
  };

  const changeSetCheck = () => {
    setInputs((values) => ({ ...values, receiveEmail: !inputs.receiveEmail }));
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const isNumber = keyCode >= 48 && keyCode <= 57;

    if (isNumber) {
      event.preventDefault();
    }
  };

  return (
    <ContactContainer id="callBack">
      <TextContainer>
        <TextContent>
          <Title>GET IN TOUCH</Title>
          <Line />
          <Form onSubmit={handleSubmit}>
            <InputForm
              id="name"
              type="text"
              name="name"
              placeholder="Your Name"
              errorMsg="Invalid name"
              required={true}
              value={inputs.name || ""}
              onChange={handleChange}
            />
            <InputForm
              id="state"
              type="state"
              name="state"
              placeholder="City/State"
              errorMsg="Invalid City/State"
              required={true}
              value={inputs.state || ""}
              onChange={handleChange}
            />
            <RadioContainer>
              <FormInputLabel>
                Any previous experience in hospice care?
              </FormInputLabel>
              <RadioButtonsContainer>
                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="Yes"
                    name="Yes"
                    checked={inputs.experience === "Yes"}
                    onClick={handleChange}
                  />
                  Yes
                </FormRadioLabel>

                <FormRadioLabel>
                  <FormRadio
                    type="radio"
                    value="No"
                    name="No"
                    checked={inputs.experience === "No"}
                    onClick={handleChange}
                  />
                  No
                </FormRadioLabel>
              </RadioButtonsContainer>
            </RadioContainer>
            <InputForm
              id="email"
              type="email"
              name="email"
              placeholder="E-mail address"
              errorMsg="Invalid email"
              required={true}
              value={inputs.email || ""}
              onChange={handleChange}
            />
            <InputForm
              id="phone"
              type="phone"
              name="phone"
              placeholder="Phone Number"
              errorMsg="Invalid phone"
              required={true}
              value={inputs.phone || ""}
              onChange={handleChange}
            />
            <CheckboxWrapper>
              <CheckboxInput
                type="checkbox"
                checked={inputs.receiveEmail}
                onChange={changeSetCheck}
              />
              <CheckboxText>
                I consent to receive information from Life Hospice
              </CheckboxText>
            </CheckboxWrapper>
            {showError && (
              <ErrorMessage>
                Please consent to receive information before sending request
              </ErrorMessage>
            )}
            <BottomSection>
              <Button type="submit"> Send Request </Button>
              {succesForm ? (
                <FormMessage>Information sent successfully</FormMessage>
              ) : (
                errorForm && (
                  <FormMessage>Error. Please try again later</FormMessage>
                )
              )}
            </BottomSection>
          </Form>
        </TextContent>
      </TextContainer>
      <ImageContainer>
        <Picture>
          <img src={BackImage} alt="Volunteer and elderly patient knitting together" />
        </Picture>
      </ImageContainer>
    </ContactContainer>
  );
};

export default ContactFormVolunteer;
